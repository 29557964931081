import light from "./mode/light";
import dark from "./mode/dark";

const theme = {
  breakpoints: ["481px", "736px", "1281px", "1441px"],
};

const themeLight = {
  ...light,
  ...theme,
};

const themeDark = {
  ...dark,
  ...theme,
};

export { themeLight, themeDark };
