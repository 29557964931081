const themeDark = {
  colors: {
    text: "#fff",
    link: "#e9daac",
    background: "#111216",
    border: "rgba(255,255,255,0.15)",
    gradient: "linear-gradient(180deg,#111216 calc(100% - 590px),#232932 100%)",
    content:
      "linear-gradient(180deg, #000000 0%, rgba(249, 250, 252, 0) 91.01%);",
  },
};

export default themeDark;
