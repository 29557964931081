const themeLight = {
  colors: {
    text: "#000",
    link: "#6166dc",
    background: "#fafafa",
    border: "rgba(8,8,11,0.15)",
    gradient: "linear-gradient(180deg, rgba(255, 255, 255, 0) calc(100% - 590px), #D9DBE0 100%)",
    content: "linear-gradient(180deg, #FFFFFF 0%, rgba(249, 250, 252, 0) 91.01%);",
  },
};

export default themeLight;
