import React from "react";
import "prismjs/themes/prism-tomorrow.min.css";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import "react-modal-video/css/modal-video.min.css";

import { Provider } from "./src/context/ThemeContext";
import "./src/styles/reset.css";
import "./src/styles/global.css";
import "@fontsource/merriweather/400.css";
import "@fontsource/merriweather/700.css";

export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>;
