import React, { createContext, useContext, useEffect, useState } from "react";

import { themeLight, themeDark } from "../styles/themes";

const defaultState = {
  isDark: false,
  theme: themeLight,
  changeTheme: () => {},
};

export const ThemeContext = createContext(defaultState);

export const Provider = ({ children }) => {
  const windowGlobal = typeof window !== "undefined" && window;
  const [isDark, setTheme] = useState(false);

  useEffect(() => {
    const mmioDarkMode = windowGlobal.localStorage.getItem("mmio-dark-mode");
    if (!mmioDarkMode) {
      windowGlobal.localStorage.setItem("mmio-dark-mode", isDark);
    } else {
      setTheme(mmioDarkMode === "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    windowGlobal.localStorage.setItem("mmio-dark-mode", isDark);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDark]);

  return (
    <ThemeContext.Provider
      value={{
        isDark,
        theme: isDark ? themeDark : themeLight,
        changeTheme: () => setTheme(!isDark),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const { isDark, theme, changeTheme } = useContext(ThemeContext);

  return {
    isDark,
    theme,
    changeTheme,
  };
};
